import React from 'react';
import Loader from 'react-loader-spinner';

const LoaderSpinner = ({ loading = false }) => {
    return (
        <div>
            <Loader
                type="ThreeDots"
                color="#00BFFF"
                height={65}
                width={65}
                visible={loading ? 'true' : 'false'}
            />
        </div>
    )
};

export default LoaderSpinner;