import * as api from './api'
export const GET_USER_CHAT_EVENTS = '/chat/getUserChatEvents'
export const DELETE_CHAT = '/chat/DELETE_CHAT'
export const GET_CHAT_MESSAGES = '/chat/GET_CHAT_MESSAGES'
export const CHAT_IMAGE_UPLOAD = '/chat/CHAT_IMAGE_UPLOAD'
export const SCAN_QR_CODE = '/chat/SCAN_QR_CODE'

export const getUserChatEvents = (data) => {
  return {
    type: GET_USER_CHAT_EVENTS,
    payload: api.get_user_chat_events(data)
  }
}

export const deleteChat = (data) => {
  return {
    type: DELETE_CHAT,
    payload: api.delete_chat(data)
  }
}

export const getChatMessages = (data) => {
  return {
    type: GET_CHAT_MESSAGES,
    payload: api.get_chat_messages(data)
  }
}

export const chatImageUpload = (data) => {
  return {
    type: CHAT_IMAGE_UPLOAD,
    payload: api.chat_image_upload(data)
  }
}

export const scanQrCode = (data) => {
  return {
    type: SCAN_QR_CODE,
    payload: api.scan_qr_code(data)
  }
}