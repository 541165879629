import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME ? process.env.REACT_APP_API_HOSTNAME : 'https://developer.webvilleedemo.xyz'

export const userSignup = data => {
  return fetch(`${HOSTNAME}/api/signup/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const userSignin = data => {
  return fetch(`${HOSTNAME}/api/signin/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const reset_password = data => {
  return fetch(`${HOSTNAME}/api/reset_password/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const change_password = data => {
  return fetch(`${HOSTNAME}/api/change_password/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const set_password = data => {
  return fetch(`${HOSTNAME}/api/set_password/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const update_profile = data => {
  return fetch(`${HOSTNAME}/api/update_profile`, {
    method: 'POST',
    body: data,
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_user_details = Authorization => {
  return fetch(`${HOSTNAME}/api/get_user_details`, {
    method: 'GET',
    headers: {
      'Authorization': Authorization,
    }
  })
    .then(res => {
      return res.json();
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_all_interests = () => {
  return fetch(`${HOSTNAME}/api/get_all_interests/`, {
    method: 'GET',
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const add_user_interests = data => {
  return fetch(`${HOSTNAME}/api/add_user_interests/`, {
    method: 'POST',
    body: data,
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_content = data => {
  return fetch(`${HOSTNAME}/api/get_content/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_premium_subscriptions = data => {
  return fetch(`${HOSTNAME}/api/get_premium_subscriptions`, {
    method: 'GET',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const contact = data => {
  return fetch(`${HOSTNAME}/api/contact`, {
    method: 'GET',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const check_social_signin = data => {
  return fetch(`${HOSTNAME}/api/check_social_signin/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const social_signup = data => {
  return fetch(`${HOSTNAME}/api/social_signup/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const user_contact_form = data => {
  return fetch(`${HOSTNAME}/api/contact/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}


export const update_two_way_authentication = () => {
  return fetch(`${HOSTNAME}/api/update_two_way_authentication`, {
    method: 'GET',
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const update_sound_notification = () => {
  return fetch(`${HOSTNAME}/api/update_notification_setting`, {
    method: 'POST',
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const delete_account = data => {
  return fetch(`${HOSTNAME}/api/delete_account`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const otp_verification = data => {
  return fetch(`${HOSTNAME}/api/otp_verification/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const resend_otp = data => {
  return fetch(`${HOSTNAME}/api/resend_otp/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_notifications = data => {
  return fetch(`${HOSTNAME}/api/get_notifications/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_statistics_data = data => {
  return fetch(`${HOSTNAME}/api/get_statistics_data/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const user_events = data => {
  return fetch(`${HOSTNAME}/api/user_events/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const past_event_reviews = data => {
  return fetch(`${HOSTNAME}/api/past_event_reviews/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const rating = data => {
  return fetch(`${HOSTNAME}/api/rating/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const send_report = data => {
  return fetch(`${HOSTNAME}/api/send_report/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const app_rate_us = data => {
  return fetch(`${HOSTNAME}/api/app_rate_us/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_event_users = data => {
  return fetch(`${HOSTNAME}/api/get_event_users/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_followers = data => {
  return fetch(`${HOSTNAME}/api/get_followers/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_followings = data => {
  return fetch(`${HOSTNAME}/api/get_followings/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const follow_unfollow = data => {
  return fetch(`${HOSTNAME}/api/follow_unfollow/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_user_details_by_id = data => {
  return fetch(`${HOSTNAME}/api/get_user_details_by_id/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const update_user_location = data => {
  return fetch(`${HOSTNAME}/api/update_user_location`, {
    method: 'POST',
    body: data,
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}


export const check_data_by_email = data => {
  return fetch(`${HOSTNAME}/api/check_data_by_email`, {
    method: 'POST',
    body: data,
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const update_paypal_details = data => {
  return fetch(`${HOSTNAME}/api/update_paypal_details/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}



// ************************************* Business User Sections ***************************************
export const advertisement_create = data => {
  return fetch(`${HOSTNAME}/api/advertisement_create/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_ads_statistics_data = data => {
  return fetch(`${HOSTNAME}/api/get_ads_statistics_data/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}