import fetch from 'isomorphic-fetch'
import { toast } from 'react-toastify'
import { logout } from '../views/utils'


const handleHTTPErrors = res => {
  if (res.ok) return res
  return res.json().then(err => {
    if (window.location.pathname === `${process.env.PUBLIC_URL}/terms` || window.location.pathname === `${process.env.PUBLIC_URL}/privacy-policy` || window.location.pathname.includes('single-blog')) {
      return
    } else {
      if (err.status === 401) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_RIGHT
        });
        logout(true);
        setTimeout(() => {
          window.location.replace(`${window.location.origin}${process.env.PUBLIC_URL}`);
        }, 3000);
      } else if (err.status === 500) {
        toast.error('Something went wrong!', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }

    throw err
  })
}

export default (url, options) => {
  const jwtToken = localStorage.getItem('Authorization')
  if (jwtToken) {
    let authAddedOptions = options
    if (typeof options !== 'object') {
      authAddedOptions = {}
    }
    if (typeof authAddedOptions.headers !== 'object') {
      authAddedOptions.headers = {}
    }
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: jwtToken
    }
    return fetch(url, authAddedOptions).then(handleHTTPErrors)
  } else {
    return fetch(url, options).then(handleHTTPErrors)
  }
}
