import App from "./component";
import { connect } from "react-redux";
import { getDynamicContent } from "../store/event/duck";
import { getUserChatEvents } from "../store/chat/duck";

// const AppContainer = (App)
// export default AppContainer

const AppContainer = connect(
  // Map state to props
  (state) => ({
    // message: state.user.message,
    // user: state.user.user,
    // loginPhase: state.user.loginPhase,
    // loginError: state.user.loginError
  }),
  {
    getUserChatEvents,
    getDynamicContent,
  }
  // Map actions to dispatch and props
)(App);

export default AppContainer;
