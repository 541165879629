import { assign } from 'lodash'
import { Record } from 'immutable'

import * as api from './api'
import { combineEpics } from 'redux-observable'


export const SIGNUP_USER = '/users/SIGNUP_USER'
export const LOGIN_USER = '/users/LOGIN_USER'
export const LOGIN_USER_SUCCESS = '/users/LOGIN_USER_SUCCESS'
export const LOGIN_USER_ERROR = '/users/LOGIN_USER_ERROR'

export const LOGOUT_USER = '/users/LOGOUT_USER'
export const LOGOUT_USER_SUCCESS = '/users/LOGOUT_USER_SUCCESS'
export const FORGOT_PASSWORD = '/users/FORGOT_PASSWORD'
export const CHANGE_PASSWORD = '/users/CHANGE_PASSWORD'
export const UPDATE_PROFILE = '/users/UPDATE_PROFILE'
export const GET_USER_DETAILS = '/users/GET_USER_DETAILS'
export const GET_CONTENT = '/users/GET_CONTENT'
export const GETALLINTERESTS = '/users/GETALLINTERESTS'
export const ADD_USER_INTEREST = '/users/ADD_USER_INTEREST'
export const SOCIAL_SIGNUP = '/users/SOCIAL_SIGNUP'
export const CHECK_SOCIAL_SIGNIN = '/users/CHECK_SOCIAL_SIGNIN'
export const USER_CONTACT_FORM = '/users/USER_CONTACT_FORM'
export const TWO_FACTOR_AUTH = '/users/TWO_FACTOR_AUTH'
export const DELETE_ACCOUNT = '/users/DELETE_ACCOUNT'
export const OTP_VERIFICATION = '/users/OTP_VERIFICATION'
export const RESEND_OTP = '/users/RESEND_OTP'
export const GETALLNOTIFICATIONS = '/users/GETALLNOTIFICATIONS'
export const GETSTATISTICSDATA = '/users/GETSTATISTICSDATA'
export const GETUSEREVENTS = '/users/GETUSEREVENTS'
export const PASTEVENTREVIEWS = '/users/PASTEVENTREVIEWS'
export const RATING = '/users/RATING'
export const SENDREPORT = '/users/SENDREPORT'
export const APPRATEUS = '/users/APPRATEUS'
export const GETEVENTUSERS = '/users/GETEVENTUSERS'
export const GETFOLLOWERS = '/users/GETFOLLOWERS'
export const GETFOLLOWINGS = '/users/GETFOLLOWINGS'
export const FOLLOWUNFOLLOW = '/users/FOLLOWUNFOLLOW'
export const GETUSERDETAILSBYID = '/users/GETUSERDETAILSBYID'
export const ADVERTISEMENTCREATE = '/users/ADVERTISEMENTCREATE'
export const GETADSSTATISTICSDATA = '/users/GETADSSTATISTICSDATA'
export const SET_PASSWORD = '/users/SET_PASSWORD'
export const UPDATE_USER_LOCATION = '/users/UPDATE_USER_LOCATION'
export const CHECK_DATA_BY_EMAIL = '/users/CHECK_DATA_BY_EMAIL'
export const UPDATE_PAYPAL_DETAILS = '/users/UPDATE_PAYPAL_DETAILS'
export const UPDATE_SOUND_NOTIFICATION = '/users/UPDATE_SOUND_NOTIFICATION'

const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  token: null,
  // phase: INIT,
  // userPhase: INIT,
  user: null,
  error: null,
  message: null,
  // loginPhase: INIT,
  loginError: null
}

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = '' // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues))
  }
}


/***********************************
 * Reducer
 ***********/
// eslint-disable-next-line complexity, max-statements

export default function (state = new InitialState(), action = {}) {
  switch (action.type) {

    

    default: {
      return state
    }
  }
}


/***********************************
 * Action Creators
 ***********/

export const userSignin = credentials => {
  return {
    type: LOGIN_USER,
    payload: api.userSignin(credentials)
  }
}

export const userSignup = signupdata => {
  return {
    type: SIGNUP_USER,
    payload: api.userSignup(signupdata)
  }
}

export const ForgotPassword = email => {
  return {
    type: FORGOT_PASSWORD,
    payload: api.reset_password(email)
  }
}

export const ChangePassword = passwords => {
  return {
    type: CHANGE_PASSWORD,
    payload: api.change_password(passwords)
  }
}

export const SetPassword = passwords => {
  return {
    type: SET_PASSWORD,
    payload: api.set_password(passwords)
  }
}

export const UpdateProfile = data => {
  return {
    type: UPDATE_PROFILE,
    payload: api.update_profile(data)
  }
}

export const GetUserDetails = Authorization => {
  return {
    type: GET_USER_DETAILS,
    payload: api.get_user_details(Authorization)
  }
}

export const GetContent = data => {
  return {
    type: GET_CONTENT,
    payload: api.get_content(data)
  }
}

export const GetAllInterest = () => {
  return {
    type: GETALLINTERESTS,
    payload: api.get_all_interests()
  }
}

export const AddUserInterests = data => {
  return {
    type: ADD_USER_INTEREST,
    payload: api.add_user_interests(data)
  }
}

export const CheckSocialSignin = data => {
  return {
    type: CHECK_SOCIAL_SIGNIN,
    payload: api.check_social_signin(data)
  }
}

export const SocialSignup = data => {
  return {
    type: SOCIAL_SIGNUP,
    payload: api.social_signup(data)
  }
}

export const userContactForm = data => {
  return {
    type: USER_CONTACT_FORM,
    payload: api.user_contact_form(data)
  }
}

export const twoFactorAuth = data => {
  return {
    type: TWO_FACTOR_AUTH,
    payload: api.update_two_way_authentication(data)
  }
}

export const updateSoundNotification = data => {
  return {
    type: UPDATE_SOUND_NOTIFICATION,
    payload: api.update_sound_notification(data)
  }
}

export const DeleteAccount = data => {
  return {
    type: DELETE_ACCOUNT,
    payload: api.delete_account(data)
  }
}

export const OtpVerification = data => {
  return {
    type: OTP_VERIFICATION,
    payload: api.otp_verification(data)
  }
}

export const ResendOTP = data => {
  return {
    type: RESEND_OTP,
    payload: api.resend_otp(data)
  }
}

export const getNotifications = data => {
  return {
    type: GETALLNOTIFICATIONS,
    payload: api.get_notifications(data)
  }
}

export const getStatisticsData = data => {
  return {
    type: GETSTATISTICSDATA,
    payload: api.get_statistics_data(data)
  }
}

export const getUserEvents = data => {
  return {
    type: GETUSEREVENTS,
    payload: api.user_events(data)
  }
}

export const pastEventReviews = data => {
  return {
    type: PASTEVENTREVIEWS,
    payload: api.past_event_reviews(data)
  }
}

export const writeRating = data => {
  return {
    type: RATING,
    payload: api.rating(data)
  }
}

export const sendReport = data => {
  return {
    type: SENDREPORT,
    payload: api.send_report(data)
  }
}

export const appRateUs = data => {
  return {
    type: APPRATEUS,
    payload: api.app_rate_us(data)
  }
}
export const getEventUsers = data => {
  return {
    type: GETEVENTUSERS,
    payload: api.get_event_users(data)
  }
}
export const getFollowers = data => {
  return {
    type: GETFOLLOWERS,
    payload: api.get_followers(data)
  }
}
export const getFollowings = data => {
  return {
    type: GETFOLLOWINGS,
    payload: api.get_followings(data)
  }
}
export const followUnfollow = data => {
  return {
    type: FOLLOWUNFOLLOW,
    payload: api.follow_unfollow(data)
  }
}
export const getUserDetailsById = data => {
  return {
    type: GETUSERDETAILSBYID,
    payload: api.get_user_details_by_id(data)
  }
}

export const updateUserLocation = data => {
  return {
    type: UPDATE_USER_LOCATION,
    payload: api.update_user_location(data)
  }
}

export const checkDataByEmail = data => {
  return {
    type: CHECK_DATA_BY_EMAIL,
    payload: api.check_data_by_email(data)
  }
}

export const updatePaypalDetails = data => {
  return {
    type: UPDATE_PAYPAL_DETAILS,
    payload: api.update_paypal_details(data)
  }
}


// ************************************* Business User Sections ***************************************

export const createAdvertisement = (data) => {
  return {
    type: ADVERTISEMENTCREATE,
    payload: api.advertisement_create(data)
  }
}

export const getAdsStatisticsData = (data) => {
  return {
    type: GETADSSTATISTICSDATA,
    payload: api.get_ads_statistics_data(data)
  }
}



/***********************************
 * Epics
 ***********************************/

// const loginUserEpic = action$ =>
//   action$.pipe(
//     ofType(LOGIN_USER),
//     mergeMap(action => {

//       return fromPromise(api.userSignin(action.payload)).pipe(
//         flatMap(payload => [
//           {
//             type: LOGIN_USER_SUCCESS,
//             payload
//           }
//         ]),
//         catchError(error =>
//           of({
//             type: LOGIN_USER_ERROR,
//             error
//           })
//         )
//       )
//     })
//   )                       


export const userEpic = combineEpics(
  // loginUserEpic
)