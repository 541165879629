import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME ? process.env.REACT_APP_API_HOSTNAME : 'https://developer.webvilleedemo.xyz'

export const get_user_chat_events = data => {
  return fetch(`${HOSTNAME}/api/user_chat_events/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const delete_chat = data => {
  return fetch(`${HOSTNAME}/api/delete_chat/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_chat_messages = data => {
  return fetch(`${HOSTNAME}/api/get_chat_messages/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const chat_image_upload = data => {
  return fetch(`${HOSTNAME}/api/chat_image_upload/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const scan_qr_code = data => {
  return fetch(`${HOSTNAME}/api/scan_qr_code/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}