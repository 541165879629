
export const logout = (is_session_expired = false) => {
    if (!is_session_expired) { localStorage.setItem('logged_out', localStorage.getItem('user_role')) }
    localStorage.removeItem('Authorization')
    localStorage.removeItem('profile_image')
    localStorage.removeItem('email')
    localStorage.removeItem('longitude')
    localStorage.removeItem('latitude')
    localStorage.removeItem('first_name')
    localStorage.removeItem('last_name')
    localStorage.removeItem('user_role')
    localStorage.removeItem('referral_code')
    localStorage.removeItem('social_type')
    localStorage.removeItem('social_email')
    localStorage.removeItem('social_id')
    localStorage.removeItem('email_by_input')
    localStorage.removeItem('__paypal_storage__')
    localStorage.removeItem('ref_code')
    localStorage.removeItem('createEventLocalData')
    localStorage.removeItem('b64Img')
    localStorage.removeItem('adObject')
    localStorage.removeItem('ads_array')
};

