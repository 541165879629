import * as api from './api'
export const GET_DYNAMIC_CONTENT = '/event/GET_DYNAMIC_CONTENT'
export const CREATE_EVENT = '/event/CREATE_EVENT'
export const GET_EVENT_CREATE_INTERESTS = '/event/GET_EVENT_CREATE_INTERESTS'
export const GET_USER_EVENTS = '/event/GET_USER_EVENTS'
export const ADVERTISEMENT_VIEW_LIKE = '/event/ADVERTISEMENT_VIEW_LIKE'
export const EVENT_SWIPE = '/event/EVENT_SWIPE'
export const EVENT_ADS_PREMIUM_SUBSCRIPTION_CHECK = '/event/EVENT_ADS_PREMIUM_SUBSCRIPTION_CHECK'
export const EVENT_JOIN = '/event/EVENT_JOIN'
export const DELETE_EVENT_BY_ID = '/event/DELETE_EVENT_BY_ID'
export const GET_EVENT_DETAILS = '/event/GET_EVENT_DETAILS'
export const EVENT_REJECT = '/event/EVENT_REJECT'

export const getDynamicContent = () => {
  return {
    type: GET_DYNAMIC_CONTENT,
    payload: api.get_dynamic_content()
  }
}

export const getEventCreateInterests = () => {
  return {
    type: GET_EVENT_CREATE_INTERESTS,
    payload: api.get_event_create_interests()
  }
}

export const createEvent = (data) => {
  return {
    type: CREATE_EVENT,
    payload: api.create_event(data)
  }
}

export const advertisementViewLike = (data) => {
  return {
    type: ADVERTISEMENT_VIEW_LIKE,
    payload: api.advertisement_view_like(data)
  }
}

export const getUserEvents = (data) => {
  return {
    type: GET_USER_EVENTS,
    payload: api.get_user_events(data)
  }
}

export const eventSwipe = (data) => {
  return {
    type: EVENT_SWIPE,
    payload: api.event_swipe(data)
  }
}

export const eventJoin = (data) => {
  return {
    type: EVENT_JOIN,
    payload: api.event_join(data)
  }
}

export const deleteEventById = (data) => {
  return {
    type: DELETE_EVENT_BY_ID,
    payload: api.delete_event(data)
  }
}

export const getEventDetails = (data) => {
  return {
    type: GET_EVENT_DETAILS,
    payload: api.get_event_details(data)
  }
}

export const eventAdsPremiumSubscriptionCheck = (data) => {
  return {
    type: EVENT_ADS_PREMIUM_SUBSCRIPTION_CHECK,
    payload: api.event_ads_premium_subscription_check(data)
  }
}

export const eventReject = (data) => {
  return {
    type: EVENT_REJECT,
    payload: api.event_reject(data)
  }
}
