import { fetch } from '../../utils'
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME ? process.env.REACT_APP_API_HOSTNAME : 'https://developer.webvilleedemo.xyz'


export const get_dynamic_content = () => {
  return fetch(`${HOSTNAME}/api/base_url`, {
    method: 'GET',
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}


export const get_guest_tickets = data => {
  return fetch(`${HOSTNAME}/api/get_guest_tickets`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const create_event = data => {
  return fetch(`${HOSTNAME}/api/create_event/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const advertisement_view_like = data => {
  return fetch(`${HOSTNAME}/api/advertisement_view_like/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_user_events = data => {
  return fetch(`${HOSTNAME}/api/get_events/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}


export const get_event_create_interests = data => {
  return fetch(`${HOSTNAME}/api/get_event_create_interests`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const rating = data => {
  return fetch(`${HOSTNAME}/api/rating`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const send_report = data => {
  return fetch(`${HOSTNAME}/api/send_report`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const event_swipe = data => {
  return fetch(`${HOSTNAME}/api/event_swipe/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const event_ads_premium_subscription_check = data => {
  return fetch(`${HOSTNAME}/api/event_ads_premium_subscription_check/`, {
    method: 'GET',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const event_join = data => {
  return fetch(`${HOSTNAME}/api/event_join/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}


export const delete_event = data => {
  return fetch(`${HOSTNAME}/api/delete_event/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const get_event_details = data => {
  return fetch(`${HOSTNAME}/api/get_event_details/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}

export const event_reject = data => {
  return fetch(`${HOSTNAME}/api/event_reject/`, {
    method: 'POST',
    body: data
  })
    .then(res => {
      return res.json()
    })
    .then(payload => {
      return payload
    })
    .catch(error => {
      throw error
    })
}
