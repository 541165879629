import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Appcontainer from "./App/container";
import { CookiesProvider } from "react-cookie";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "react-toastify/dist/ReactToastify.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const history = createBrowserHistory();

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <Router basename={""} history={history}>
        <Appcontainer />
      </Router>
    </CookiesProvider>
  </Provider>,
  document.getElementById("root")
);
