import { createStore, combineReducers, applyMiddleware } from "redux";
import { reducer as formReducer } from "redux-form";
import thunk from "redux-thunk";
import promise from "redux-promise-middleware";
import { createEpicMiddleware, combineEpics } from "redux-observable";
import app from "./app";
import user, { userEpic } from "./users/duck";

// Bundling Epics
const rootEpic = combineEpics(userEpic);

// Creating Bundled Epic
const epicMiddleware = createEpicMiddleware();

// Define Middleware
const middleware = [thunk, promise, epicMiddleware];

// Define Reducers
const reducers = combineReducers({
  user,
  app,
  form: formReducer,
});

// Create Store
const store = createStore(reducers, applyMiddleware(...middleware));
epicMiddleware.run(rootEpic);
export default store;
