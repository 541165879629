export let message = ''
export let corona_popup_content = {}

export const setMessage = (value) => {
    message = value
}

export const getMessage = () => {
    return message
}

export const guest_ticket_popup_content = () => {
    return corona_popup_content
}

export const set_guest_ticket_popup_content = (value) => {
    corona_popup_content = value
}
