import React, { Component, Suspense } from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { setMessage } from "../helper";
import io from "socket.io-client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import audio from "../views/Chat/notification.mp3";
import LoaderSpinner from "../views/spinner";

const ENDPOINT = process.env.REACT_APP_CHAT_SOCKET_URL; //Chat Socket URL

const HomeContainer = React.lazy(() => import("../views/Home/container"));
const ProfileContainer = React.lazy(() => import("../views/Profile/container"));
const ShopContainer = React.lazy(() => import("../views/Shop/container"));
const BlogContainer = React.lazy(() => import("../views/Blog/container"));
const ChatContainer = React.lazy(() => import("../views/Chat/container"));
const SingleBlogContainer = React.lazy(() =>
  import("../views/SingleBlog/container")
);
const NotificationContainer = React.lazy(() =>
  import("../views/Dropdown/Notification/container")
);
const TermsContainer = React.lazy(() =>
  import("../views/Dropdown/Terms/container")
);
const PrivacyContainer = React.lazy(() =>
  import("../views/Dropdown/PrivacyPolicy/container")
);
const TinderContainer = React.lazy(() => import("../views/Tinder/container"));
const OnboardingContainer = React.lazy(() =>
  import("../views/Onboarding/container")
);
const EventsingleContainer = React.lazy(() =>
  import("../views/Eventsingle/container")
);
const BusinessAdsContainer = React.lazy(() =>
  import("../views/Business_ads/container")
);
const BusinessAdsProfileContainer = React.lazy(() =>
  import("../views/Business_ads_profile/container")
);
const PageNotFoundComponent = React.lazy(() =>
  import("../views/404/component")
);
const GuidelineContainer = React.lazy(() =>
  import("../views/Dropdown/Guideline/container")
);
const AndroidPaypalCheckout = React.lazy(() =>
  import("../views/Android_paypal_checkout/checkout")
);
const AndroidPaypalSuccess = React.lazy(() =>
  import("../views/Android_paypal_checkout/success")
);
const AndroidPaypalFailed = React.lazy(() =>
  import("../views/Android_paypal_checkout/failed")
);
const GuidelineMobileComponent = React.lazy(() =>
  import("../views/Dropdown/Guideline/guidelineMobileComponent")
);

const PrivateRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem("Authorization");
  const isAuthed = user ? true : false;
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthed ? (
          <div>{React.createElement(component, props)}</div>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component, ...rest }) => {
  const user = window.localStorage.getItem("Authorization");
  const isAuthed = user ? true : false;
  // loggedIn = isAuthed
  return (
    <Route
      {...rest}
      exact
      render={(props) =>
        isAuthed ? (
          <Redirect
            to={{
              pathname: "/home",
              state: { from: props.location },
            }}
          />
        ) : (
          <div>{React.createElement(component, props)}</div>
        )
      }
    />
  );
};

export const AppContext = React.createContext("light");

class App extends Component {
  start = () => {
    new Audio(audio).play();
  };

  constructor(props) {
    super(props);
    this.state = {
      socket: null,
      logged_in_user_id: "",
    };
  }

  componentDidMount() {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?&client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}&disable-funding=credit,card`;
    script.async = true;
    script.id = "paypalscript";
    document.body.appendChild(script);
  }

  get_user_chat_events = async () => {
    let formData = new FormData();
    formData.append("page", 0);
    const all_events = await this.props.getUserChatEvents(formData);

    if (all_events.value.status === 200) {
      this.setState({ logged_in_user_id: all_events.value.user_id });

      //to connect all the chat events with sockets to receive and send messages
      all_events.value.events.map((event) => {
        this.state.socket.emit(
          "join_chat",
          all_events.value.user_id,
          event.event_id
        );
      });
    } else if (all_events.value.status === 201) {
      console.log("Something went wrong can't connect to Chat Sockets");
    }
  };

  //getting dynamic content from api
  getDynamicContent = async () => {
    const dynamicData = await this.props.getDynamicContent();
    if (dynamicData.value.status === 200) {
      console.log(dynamicData.value.data);
    } else if (dynamicData.value.status === 201) {
      console.log("internal server error");
    }
  };

  connectSockets = () => {
    //socket stuff, Note: socket will only connect if there is no socket conection available
    if (this.state.socket === null) {
      const socket = io.connect(ENDPOINT);
      this.setState({ socket });
      if (localStorage.getItem("Authorization") !== null) {
        this.get_user_chat_events();
      }

      //Receive messages event handling
      socket.on("receive_message", (data) => {
        if (data.status === 200) {
          if (data.data.sender_id !== this.state.logged_in_user_id) {
            toast.info(
              `${data.data.event_name} event : ${data.data.sender_first_name}: ${data.data.message}`,
              {
                position: toast.POSITION.TOP_RIGHT,
              }
            );
            this.start();
            if (!window.location.pathname.toString().includes("chat")) {
              setMessage(data.data.message);
            }
            if (
              window.location.pathname.toString().includes("chat") &&
              this.state.current_event_id === data.data.event_id
            ) {
              const messageData = {
                event_id: data.data.event_id,
                message_id: data.data.message_id,
                receiver_id: this.state.logged_in_user_id,
              };
              this.state.socket.emit(
                "read_message",
                JSON.stringify(messageData)
              );
            }
          }
        } else {
          console.log("something went wrong please try again");
        }
      });
    }
  };

  render() {
    return (
      <AppContext.Provider value={{ connectsockets: this.connectSockets }}>
        <BrowserRouter>
          <div>
            <Suspense
              fallback={
                <div className="loaderScreen">
                  {" "}
                  <LoaderSpinner loading={true} />
                </div>
              }
            >
              <Switch>
                <PublicRoute
                  exact
                  path="/"
                  {...this.props}
                  component={HomeContainer}
                />
                <PrivateRoute
                  exact
                  path="/profile"
                  {...this.props}
                  component={ProfileContainer}
                />
                <PrivateRoute exact path="/shop" {...this.props}>
                  <ShopContainer />
                </PrivateRoute>
                <PrivateRoute
                  exact
                  path="/blog"
                  {...this.props}
                  component={BlogContainer}
                />
                <PrivateRoute exact path="/chat" {...this.props}>
                  <ChatContainer socket={this.state.socket} />
                </PrivateRoute>
                <Route
                  exact
                  path="/single-blog/:id"
                  {...this.props}
                  component={SingleBlogContainer}
                />
                <PrivateRoute
                  exact
                  path="/notification"
                  {...this.props}
                  component={NotificationContainer}
                />
                <Route
                  exact
                  path="/terms"
                  {...this.props}
                  component={TermsContainer}
                />
                <Route
                  exact
                  path="/privacy-policy"
                  {...this.props}
                  component={PrivacyContainer}
                />
                <PrivateRoute
                  exact
                  path="/home"
                  {...this.props}
                  component={TinderContainer}
                />
                <PrivateRoute
                  exact
                  path="/onboarding"
                  {...this.props}
                  component={OnboardingContainer}
                />
                <PrivateRoute
                  exact
                  path="/event-single/:id"
                  {...this.props}
                  component={EventsingleContainer}
                />
                <PrivateRoute
                  exact
                  path="/ads"
                  {...this.props}
                  component={BusinessAdsContainer}
                />
                <PrivateRoute
                  exact
                  path="/ads-profile"
                  {...this.props}
                  component={BusinessAdsProfileContainer}
                />
                <PrivateRoute
                  exact
                  path="/guideline"
                  {...this.props}
                  component={GuidelineContainer}
                />
                <Route
                  exact
                  path="/android_paypal_checkout"
                  {...this.props}
                  component={AndroidPaypalCheckout}
                />
                <Route
                  exact
                  path="/android_paypal_success"
                  {...this.props}
                  component={AndroidPaypalSuccess}
                />
                <Route
                  exact
                  path="/android_paypal_failed"
                  {...this.props}
                  component={AndroidPaypalFailed}
                />
                <Route
                  exact
                  path="/guidelines"
                  component={GuidelineMobileComponent}
                />
                <Route {...this.props} component={PageNotFoundComponent} />
              </Switch>
            </Suspense>
          </div>
        </BrowserRouter>
      </AppContext.Provider>
    );
  }
}

export default App;
